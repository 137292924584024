<template>
  <!--begin::List Widget 4-->
  <div
    class="card card-custom card-stretch gutter-b"
    id="cyber-list"
    v-if="loaded"
  >
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark-75">
        {{ $t("dashboard.cybersecurity.list") }}
      </h3>
      <div class="card-toolbar">
        <b-button
          class="font-weight-bolder mr-2"
          :variant="table ? 'primary' : 'outline-secondary'"
          pill
          @click="table = !table"
        >
          {{ $t("dashboard.cybersecurity.table") }}
        </b-button>
        <b-button
          class="font-weight-bolder"
          :variant="!table ? 'primary' : 'outline-secondary'"
          pill
          @click="table = !table"
        >
          {{ $t("dashboard.cybersecurity.graph") }}
        </b-button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-auto" v-if="!table">
      <!--begin::Chart-->
      <apexchart type="radar" :options="options" :series="series"></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
    <div class="card-body pt-2" v-else>
      <div
        v-for="dimension in cyberDimensions"
        :key="dimension.id"
        class="d-flex flex-wrap"
      >
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-5">
          <!--begin::Bullet-->
          <span
            :class="
              'bullet bullet-bar ' +
                getStatusClass(dimension.score) +
                ' align-self-stretch'
            "
          ></span>
          <!--end::Bullet-->
          <!--begin::Checkbox-->
          <label
            :class="
              'checkbox checkbox-lg ' +
                getStatusClass(dimension.score) +
                ' checkbox-single flex-shrink-0 m-0 mx-4'
            "
            style="cursor: auto;"
          >
            <input type="checkbox" disabled />
            <span style="background: none; " class="pl-1 pb-1">
              <span
                :class="getStatusClass(dimension.score)"
                :style="{
                  display: 'contents',
                  fontWeight: 'bold',
                  height: '100%',
                  width: '100%'
                }"
              >
                {{
                  dimension.score !== false || dimension.score !== undefined
                    ? $n(dimension.score / 10, "percent")
                    : "?"
                }}
                <!--end::Svg Icon-->
              </span>
            </span>
          </label>
          <!--end::Checkbox-->
          <!--begin::Text-->
          <div
            class="d-flex align-items-start justify-content-between flex-column"
          >
            <div
              class="text-dark-75 font-weight-bold font-size-lg text-left mb-0 pb-0"
              v-html="
                $t(
                  `tables.dimensionIds.dimension.${dimension.constant}.DEFAULT.title`
                )
              "
            />
            <div
              class="text-muted text-left font-size-sm mt-0 pt-0"
              v-html="dimension.desc"
            />
          </div>
          <!--end::Text-->
        </div>
        <!--end:Item-->
        <span
          :class="
            `ml-auto mt-2 font-size-lg py-1 label label-light ${getStatusClass(
              dimension.score
            )} font-weight-bolder label-inline cyber-label`
          "
        >
          {{ $t(`dashboard.sessions.status.${dimension.status}`) }}
        </span>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end:List Widget 4-->
</template>

<script>
import _ from "lodash";
import { getClassByValueAndBase } from "@/utils/helpers";
import { mapGetters } from "vuex";

export default {
  name: "CyberList",
  props: {
    dimensions: {
      type: Array,
      required: true
    },
    cybersecurity: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      table: false,
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant", "isConstantsActive"]),
    cyberDimensions() {
      let result = [];
      _.forEach(this.dimensions, e => {
        let status = "";
        if (e.value === false) status = "undefined";
        else if (e.value < 5) status = "bad";
        else if (e.value < 6.5) status = "fair";
        else if (e.value < 8) status = "good";
        else status = "excelent";
        result.push({
          id: e.id,
          constant: this.getConstant(e.id),
          score: e.value,
          status: status
        });
      });
      return _.orderBy(result, e => e.score, ["desc"]);
    },
    options() {
      let self = this;
      return {
        chart: {
          height: 300,
          type: "radar",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: value => self.$n(value / 10, "percent"),
          style: {
            fontSize: "14px",
            fontFamily: "Raleway, Helvetica, sans-serif",
            cssClass: "text-dark-75"
          },
          background: {
            enabled: true,
            padding: 5,
            borderWidth: 0
          }
        },
        plotOptions: {
          radar: {
            size: 165,
            polygons: {
              strokeColors: [
                "#e8e8e8",
                "#FFF",
                "#e8e8e8",
                "#FFF",
                "#e8e8e8",
                "#FFF",
                "#e8e8e8",
                "#FFF",
                "#e8e8e8",
                "#FFF"
              ],
              strokeWidth: 0.5,
              connectorColors: "#ffffff",
              fill: {
                colors: ["transparent"]
              }
            }
          }
        },
        colors: ["#ea79c1"],
        markers: {
          size: 2,
          colors: ["#fff"],
          strokeColor: "#ea79c1",
          strokeWidth: 1
        },
        tooltip: {
          enabled: false
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: _.map(this.cybersecurity.data, e => {
            return this.$t(
              `tables.dimensionIds.dimension.${this.getConstant(
                e.id
              )}.DEFAULT.title`
            );
          }),
          labels: {
            style: {
              colors: [
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F"
              ],
              fontSize: "14px",
              fontFamily: "Raleway, Helvetica, sans-serif",
              cssClass: "text-dark-75"
            }
          }
        },
        yaxis: {
          max: 10,
          min: 0,
          show: false
        }
      };
    },
    series() {
      return [
        {
          name: this.$t("dashboard.cybersecurity.title"),
          data: _.map(this.cybersecurity.data, e => e.value || 0)
        }
      ];
    }
  },
  mounted() {
    if (this.isConstantsActive) this.loaded = true;
  },
  methods: {
    getStatusClass(value) {
      return getClassByValueAndBase(value, 10, true, true);
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loaded = true;
      }
    }
  }
};
</script>

<style lang="scss">
#cyber-list .checkbox.checkbox-lg.checkbox-single {
  height: 32px;
  width: 40px;
  border-radius: 5px;
  span {
    height: 32px;
    width: 40px;
  }
}
#cyber-list .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
#cyber-list .btn.btn-primary:focus:not(.btn-text),
#cyber-list .btn.btn-primary.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #0bb783;
  border-color: #0bb783;
}
</style>
