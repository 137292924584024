<template>
  <b-card class="card card-custom card-stretch gutter-b" no-body>
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">{{ title }}</span>
        <span
          class="text-muted mt-3 font-weight-bold font-size-sm text-left"
          v-if="subtitle"
          >{{ subtitle }}</span
        >
      </h3>
    </div>
    <div class="card-body pt-2 pb-0 mt-n3">
      <div class="tab-content mt-5" id="myTabTables1">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <tbody>
              <tr v-for="(row, index) in data" :key="row.id">
                <td class="py-6 pl-0">
                  <span
                    :class="
                      `svg-icon svg-icon-3x svg-icon-${colorByPosition(index)}`
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14 18V16H10V18L9 20H15L14 18Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.5"
                        d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4ZM5 9V6H7V11C5.9 11 5 10.1 5 9ZM19 9C19 10.1 18.1 11 17 11V6H19V9ZM17 21V22H7V21C7 20.4 7.4 20 8 20H16C16.6 20 17 20.4 17 21ZM10 9C9.4 9 9 8.6 9 8V5C9 4.4 9.4 4 10 4C10.6 4 11 4.4 11 5V8C11 8.6 10.6 9 10 9ZM10 13C9.4 13 9 12.6 9 12V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11V12C11 12.6 10.6 13 10 13Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </td>
                <td class="font-weight-bolder h4 text-left">
                  {{ row.name }}
                </td>
                <td class="font-weight-bolder h4 text-left">
                  {{ row.result !== false ? row.result : "?" }}
                </td>
                <td>
                  <b-btn
                    variant="light"
                    size="sm"
                    class="btn-icon"
                    @click="redirectToDepartmentDetail(row)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24"></polygon>
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                            x="11"
                            y="5"
                            width="2"
                            height="14"
                            rx="1"
                          ></rect>
                          <path
                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                          ></path>
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "Ranking",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    colorByPosition(index) {
      var colors = ["gold", "silver", "bronce"];
      return colors[index];
    },
    redirectToDepartmentDetail(row) {
      this.$router.push(`/controller/departments/${row.stakeholderId}`);
    }
  }
};
</script>

<style lang="scss">
.svg-icon-gold svg [fill] {
  fill: #cfb53b !important;
}
.svg-icon-silver svg [fill] {
  fill: #bfc0c8 !important;
}
.svg-icon-bronce svg [fill] {
  fill: #8c7853 !important;
}
</style>
