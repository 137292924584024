<template>
  <b-card class="card card-custom card-stretch gutter-b" no-body>
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">{{ title }}</span>
        <span
          class="text-muted mt-3 font-weight-bold font-size-sm text-left"
          v-if="subtitle"
          >{{ subtitle }}</span
        >
      </h3>
    </div>
    <div class="card-body pt-2 pb-0 mt-n3">
      <div class="tab-content mt-5" id="myTabTables1">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <tbody>
              <tr v-for="row in data" :key="row[tablekey]">
                <td
                  v-for="field in fields"
                  :key="field.id"
                  :class="
                    `${field.type !== 'progress' ? 'py-6 pl-0 text-left' : ''}`
                  "
                >
                  <div
                    class="symbol symbol-50 symbol-light mr-2"
                    v-if="field.type === 'symbol'"
                  >
                    <span class="symbol-label">
                      {{ getFieldData(row, field) }}
                    </span>
                  </div>
                  <span
                    class="ellipsis-title text-dark h4 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    v-b-tooltip.top.hover
                    :title="row.name"
                    v-if="field.type === 'title'"
                  >
                    {{ getFieldData(row, field) }}
                  </span>
                  <span
                    class="text-dark h4 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    v-if="field.type === 'number'"
                  >
                    {{ row.result == false ? "?" : row.result }}
                  </span>
                  <div class="d-flex justify-content-center">
                    <b-btn
                      variant="light"
                      size="sm"
                      class="btn-icon"
                      @click="getFieldData(row, field)"
                      v-if="field.type === 'link'"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-success">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                              x="11"
                              y="5"
                              width="2"
                              height="14"
                              rx="1"
                            ></rect>
                            <path
                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            ></path>
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </b-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "StatsTable",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    tablekey: {
      type: String,
      required: false,
      default: "id"
    }
  },
  methods: {
    getFieldData(row, field) {
      if (field.function) {
        return field.function(row);
      } else {
        return row[field.id];
      }
    }
  }
};
</script>

<style scoped>
.ellipsis-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  display: block;
  overflow: hidden;
}
</style>
