<template>
  <div v-if="awarenessDashboard">
    <b-row class="">
      <b-col xl="5" lg="5" md="10" sm="12">
        <control
          :title="$t('dashboard.cybersecurity.timeline')"
          :evolution="awarenessDashboard.evolution.series"
          :discrete="awarenessDashboard.evolution.discrete"
          :base="10"
          :height="235"
          :visibility="true"
        />
      </b-col>
      <b-col xl="2" lg="2" md="2" sm="12" class="mb-6 mb-sm-6">
        <div class="h-100 pb-2">
          <punctuation
            icon="shield-protected.svg"
            title="cybersecurity"
            :punctuation="awarenessDashboard.average"
            :evolution="awarenessDashboard.evolution"
            iconClass="tc3"
            :visibility="true"
          />
        </div>
      </b-col>
      <b-col xl="5" lg="5" md="12" sm="12">
        <ranking
          :title="$t('dashboard.cybersecurity.ranking.result.title')"
          :subtitle="$t('dashboard.cybersecurity.ranking.result.subtitle')"
          :data="resultDepartments"
          :fields="resultDepartmentsFields"
          tablekey="stakeholderId"
        />
      </b-col>
      <b-col xl="7" lg="7" md="12" sm="12">
        <cyber-list
          :dimensions="awarenessDashboard.data"
          :cybersecurity="awarenessDashboard"
        />
      </b-col>
      <b-col xl="5" lg="5" md="12" sm="12">
        <stats-table
          :title="$t('dashboard.cybersecurity.ranking.score.title')"
          :subtitle="$t('dashboard.cybersecurity.ranking.score.subtitle')"
          :data="scoreDepartments"
          :fields="scoreDepartmentsFields"
          tablekey="stakeholderId"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Control from "@/components/dashboard/Control.component";
import CyberList from "@/components/dashboard/CyberList.component";
import StatsTable from "@/components/dashboard/StatsTable.component";
import Ranking from "@/components/dashboard/Ranking.component";
import Punctuation from "@/components/dashboard/Punctuation.component";

export default {
  name: "AwarenessDashboard",
  components: {
    Control,
    CyberList,
    StatsTable,
    Ranking,
    Punctuation
  },
  data() {
    return {
      resultDepartmentsFields: [
        {
          id: "initials",
          type: "symbol",
          width: "50",
          function: val => {
            var initials = "";
            _.each(_.words(val.name), word => {
              initials = initials + word.substring(0, 1);
            });
            return initials;
          }
        },
        {
          id: "name",
          type: "title",
          width: "120"
        },
        {
          id: "result",
          type: "progress",
          width: "100"
        },
        {
          id: "actions",
          type: "link",
          function: val =>
            this.$router.push({
              name: "DepartmentDetail",
              params: {
                id: String(val.stakeholderId)
              }
            })
        }
      ],
      scoreDepartmentsFields: [
        {
          id: "initials",
          type: "symbol",
          width: "50",
          function: val => {
            var initials = "";
            _.each(_.words(val.name), word => {
              initials = initials + word.substring(0, 1);
            });
            return initials;
          }
        },
        {
          id: "name",
          type: "title",
          width: "120"
        },
        {
          id: "result",
          type: "number",
          width: "100"
        },
        {
          id: "actions",
          type: "link",
          function: val =>
            this.$router.push({
              name: "DepartmentDetail",
              params: {
                id: String(val.stakeholderId)
              }
            })
        }
      ]
    };
  },
  computed: {
    ...mapGetters("Company", ["awarenessDashboard"]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    resultDepartments() {
      return _.take(
        _.orderBy(this.awarenessDashboard.departments, ["result"], ["desc"]),
        3
      );
    },
    scoreDepartments() {
      return _.take(
        _.orderBy(
          _.filter(
            this.awarenessDashboard.departments,
            dept => dept.result !== false
          ),
          ["result"],
          ["asc"]
        ),
        4
      );
    }
  },
  methods: {
    ...mapActions("Company", ["loadAwarenessDashboard"])
  },
  mounted() {
    if (this.isConstantsActive) this.loadAwarenessDashboard();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadAwarenessDashboard();
    }
  }
};
</script>
