<template>
  <div
    :class="
      'card card-custom card-stretch gutter-b' +
        (!visibility ? ' bg-gray-200' : '')
    "
  >
    <div class="card-header border-0 d-flex flex-column">
      <div class="card-title">
        <div class="card-label font-weight-bolder text-dark-75">
          <span
            style="white-space:nowrap;"
            :class="
              'font-weight-bolder font-size-h5 title-align' +
                (!visibility ? ' text-muted' : ' text-dark-75')
            "
          >
            {{ title }}
          </span>
        </div>
      </div>
      <div class="card-toolbar">
        <span
          :class="
            `symbol symbol-80 symbol-light-${diffClass} text-center max-h-30px title-align px-1`
          "
          v-if="showEvolution"
        >
          <span
            class="symbol-label font-weight-bold font-size-lg max-h-30px"
            v-b-tooltip.hover
            :title="$t('dashboard.control.evolution')"
          >
            <span :class="`svg-icon svg-icon-${diffClass} mr-1`">
              <simple-svg :src="require(`@/assets/images/icons/${diffIcon}`)" />
            </span>
            {{ $n(diff * 10, { maximumFractionDigits: 2 }) }}%
          </span>
        </span>
      </div>
    </div>
    <div class="card-body d-flex flex-column p-0 m-0">
      <apexchart
        class="card-rounded-bottom"
        type="area"
        :height="height || 200"
        :options="options"
        :series="series"
        :key="title"
      />
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {
  getColorByValueAndBase,
  getClassByValueAndBase
} from "@/utils/helpers";

import { SimpleSVG } from "vue-simple-svg";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "Control",
  components: {
    "simple-svg": SimpleSVG
  },
  props: {
    height: {
      type: Number,
      required: false
    },
    module: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    base: {
      type: Number,
      required: false
    },
    dataType: {
      type: String,
      required: false,
      default: ""
    },
    evolution: {
      type: Array,
      required: false
    },
    discrete: {
      type: Array,
      required: false
    },
    inverse: {
      type: Boolean,
      default: false
    },
    showEvolution: {
      type: Boolean,
      default: true
    },
    visibility: {
      required: false,
      default: false
    }
  },
  methods: {
    getClassByValueAndBase(value, base, color, bg) {
      return getClassByValueAndBase(value, base, color, bg);
    }
  },
  computed: {
    value() {
      return this.evolution.length > 0 ? _.last(this.evolution).value : -1;
    },
    calcValue() {
      var value = this.value;
      var base = this.base;
      if (value < 0) {
        value = 0;
      }
      if (this.dataType === "percent") value = value / base;
      return base ? value : value * 10;
    },
    calcDataType() {
      if (this.dataType === "") {
        return { maximumFractionDigits: 2 };
      } else {
        return this.dataType;
      }
    },
    calcBase() {
      return this.base || 10;
    },
    color() {
      return getColorByValueAndBase(this.value, this.calcBase, true);
    },
    lightColor() {
      return this.color + "2b";
    },
    diff() {
      var last = 0;
      var prev = 0;
      if (this.evolution && this.evolution.length > 3) {
        last = this.evolution[this.evolution.length - 2].value.toFixed(2);
        prev = this.evolution[this.evolution.length - 3].value.toFixed(2);
        return last - prev;
      } else if (this.evolution && this.evolution.length > 1) {
        last = this.evolution[this.evolution.length - 1].value.toFixed(2);
        prev = this.evolution[this.evolution.length - 2].value.toFixed(2);
        return last - prev;
      } else {
        return 0;
      }
    },
    formattedDiff() {
      var value = this.diff;
      if (this.dataType === "percent") {
        value = value / this.base;
      } else {
        value = value.toFixed(0);
      }
      return this.$n(value, this.dataType);
    },
    diffIcon() {
      if (this.diff === 0) return "trend-none.svg";
      else if (this.diff > 0) return "trend-up.svg";
      else return "trend-down.svg";
    },
    diffClass() {
      if (this.diff > 0) return this.inverse ? "danger" : "primary";
      else if (this.diff < 0) return !this.inverse ? "danger" : "primary";
      else return "";
    },
    series() {
      return [
        {
          name: this.title,
          data: _.map(this.evolution, "value")
        }
      ];
    },
    categories() {
      return _.map(this.evolution, point => {
        return moment(point.date).isValid()
          ? moment(point.date, "YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
      });
    },
    options() {
      var self = this;
      return {
        chart: {
          type: "area",
          height: this.height,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {},
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: [this.color],
          type: "solid",
          opacity: 0.2
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [this.color]
        },
        xaxis: {
          type: "datetime",
          categories: _.map(this.evolution, point => {
            return moment(point.date).isValid()
              ? moment(point.date, "YYYY-MM-DD").format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD");
          }),
          tickAmount: this.evolution.length,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            style: {
              colors: "#B5B5C3",
              fontSize: "12px"
            }
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              color: "#B5B5C3",
              width: 1,
              dashArray: 3
            }
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: "12px"
            }
          }
        },
        yaxis: {
          min: 0,
          max: this.base || 1,
          labels: {
            show: false,
            style: {
              colors: "#B5B5C3",
              fontSize: "12px"
            }
          }
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px"
          },
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            formatter: function(t) {
              var value = t;
              var base = self.base;
              if (self.calcDataType === "percent") {
                value = t / self.base;
                base = false;
              }
              return (
                self.$n(value, self.calcDataType) + (base ? "/" + base : "")
              );
            },
            title: {
              formatter: (seriesName, object) => {
                return moment(this.categories[object.dataPointIndex]).isValid()
                  ? moment(this.categories[object.dataPointIndex]).format("ll")
                  : moment().format("ll");
              }
            }
          }
        },
        colors: [this.color],
        grid: {
          padding: {
            top: 10,
            bottom: 0
          }
        },
        markers: {
          colors: [this.lightColor],
          strokeColor: [this.color],
          strokeWidth: 2,
          discrete: self.discrete || []
        }
      };
    }
  }
};
</script>

<style lang="scss">
.title-align {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}
</style>
